<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-4">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pb-1">
          <v-form ref="form" @submit.prevent="get_convovatorias(filtro_conv)">
            <v-row class="col-12">
              <v-row>
                <v-col cols="6" sm="3" md="2">
                  <v-select
                    v-model="filtro_conv.empresa"
                    :items="empresas_select"
                    clearable
                    label="Empresa"
                    @change="get_areas_x_empresa(filtro_conv.empresa)"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="3" md="2">
                  <v-select
                    v-model="filtro_conv.area"
                    :items="areas_x_empresa"
                    clearable
                    label="Área"
                    @change="get_puestos_x_area(filtro_conv.area)"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-select
                    v-model="filtro_conv.puesto"
                    :items="puestos_x_area"
                    clearable
                    label="Puesto"
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="3" md="4" xl="3">
                  <v-text-field
                    v-model.trim="filtro_conv.busqueda"
                    label="Nombre"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="9" md="7" xl="5">
                  <v-row no-gutters>
                    <v-col cols="2" md="3" class="d-flex align-center body-1 font-weight-light">Fecha inicio</v-col>
                    <v-col>
                      <Fecha tipo="Desde" :isFiltro="true" :isInicio="true" />
                    </v-col>
                    <v-col class="ml-4">
                      <Fecha tipo="Hasta" :isFiltro="true" :isInicio="true" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="2" md="3" class="d-flex align-center body-1 font-weight-light">Fecha fin</v-col>
                    <v-col>
                      <Fecha tipo="Desde" :isFiltro="true" :isInicio="false" />
                    </v-col>
                    <v-col class="ml-4">
                      <Fecha tipo="Hasta" :isFiltro="true" :isInicio="false" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" md="5" class="d-flex align-center">
                  <v-row no-gutters>
                    <v-col cols="5" sm="12" md="6" class="d-flex justify-center">
                      <v-switch
                        v-model="filtro_conv.activa"
                        label="Activa"
                      ></v-switch>
                    </v-col>
                    <v-col cols="4" sm="12" md="3" class="d-flex align-center justify-center">
                      <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon small right>fas fa-search</v-icon></v-btn>
                    </v-col>
                    <div class="mt-5 ml-8"><LimpiarFiltro :isConv="true" /></div>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="convocatorias"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:top>
        <div class="d-flex py-2 px-3">
          <v-spacer></v-spacer>
          <div class="d-flex align-start justify-end mt-n9">
            <v-btn
              color="success"
              fab
              @click="nueva"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-row no-gutters class="d-flex justify-center">
          <PostulantesCard :icon="true" :convocatoria="item.id" />
          <v-icon
            small
            color="info"
            class="mx-2"
            title="Detalle"
            @click="ver(item)"
          >
            fas fa-eye
          </v-icon>
          <DeleteConvocatoria :item=item />
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          {{ message }}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState, mapActions } from 'vuex'
import router from '../../router'
import Fecha from '../../components/utils/Fecha'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import DeleteConvocatoria from '../../components/DeleteConvocatoria'
import PostulantesCard from '../../components/PostulantesCard'

export default {
  data() {
    return {
      panel: 1,
      load: false,
      message: 'No hay datos para los filtros seleccionados',
      areas_x_empresa: [],
      puestos_x_area: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Búsqueda', value: 'busqueda' },
        { text: 'Puesto', value: 'puesto.text' },
        { text: 'Área', value: 'area.text' },
        { text: 'Empresa', value: 'empresa.text' },
        { text: 'Vigencia desde', value: 'fecha_desde_format' },
        { text: 'Vigencia hasta', value: 'fecha_hasta_format' },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
      ],
    }
  },
  created() {
    if (this.filtro_conv.empresa !== '') {
      this.get_areas_x_empresa(this.filtro_conv.empresa)
      if (this.filtro_conv.area !== '') {
        this.get_puestos_x_area(this.filtro_conv.area)
      }
    }
    this.get_convovatorias(this.filtro_conv)
  },
  components: {
    Fecha, LimpiarFiltro, DeleteConvocatoria, PostulantesCard
  },
  computed: {
    ...mapState(['filtro_conv', 'empresas_select', 'convocatorias'])
  },
  methods: {
    ...mapActions(['set_convocatorias', 'set_filtro_conv']),

    async get_convovatorias(filtro) {
      this.set_filtro_conv(this.filtro_conv)
      this.set_convocatorias([])

      this.load = true
      this.message = 'No hay datos para los filtros seleccionados'
      let convocatorias = []
      let query = db.collection('convocatorias').where('activa', '==', filtro.activa)

      if (!(filtro.empresa === null || filtro.empresa === '')) {
        query = query.where('empresa.value', '==', filtro.empresa)

        if (!(filtro.area === null || filtro.area === '')) {
          query = query.where('area.value', '==', filtro.area)

          if (!(filtro.puesto === null || filtro.puesto === '')) {
            query = query.where('puesto.value', '==', filtro.puesto)
          }
        }
      }

      if (filtro.fecha.fin.desde !== null) query = query.where('fecha.hasta', '>=', filtro.fecha.fin.desde)
      if (filtro.fecha.fin.hasta !== null) query = query.where('fecha.hasta', '<=', filtro.fecha.fin.hasta)

      await query.get()
        .then((res) => {
          res.forEach((doc) => {
            let convocatoria = doc.data()
            convocatoria.id = doc.id
            convocatoria.fecha_desde_format = this.formatDate(convocatoria.fecha.desde)
            convocatoria.fecha_hasta_format = this.formatDate(convocatoria.fecha.hasta)
            convocatorias.push(convocatoria)
          })
        })
        .catch((error) => {
          console.log(error)
        })

      // filtros
      convocatorias = convocatorias.filter(convocatoria => this.removeAccents(convocatoria.busqueda).indexOf(this.removeAccents(filtro.busqueda)) >= 0)
      if (filtro.fecha.inicio.desde !== null) convocatorias = convocatorias.filter(convocatoria => convocatoria.fecha.desde >= filtro.fecha.inicio.desde)
      if (filtro.fecha.inicio.hasta !== null) convocatorias = convocatorias.filter(convocatoria => convocatoria.fecha.desde <= filtro.fecha.inicio.hasta)

      this.set_convocatorias(convocatorias)
      this.load = false
    },

    async get_areas_x_empresa(empresa) {
      this.filtro_conv.puesto = ''
      this.areas_x_empresa = []
      this.puestos_x_area = []

      if (empresa !== null && empresa !== '') {
        await db.collection('areas').where('inhabilitado', '==', false).where('empresa', '==', empresa).get()
          .then((query) => {
            query.forEach((doc) => {
              this.areas_x_empresa.push({
                text: doc.data().abreviacion,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    async get_puestos_x_area(area) {
      this.puestos_x_area = []

      if (area !== null && area !== '') {
        await db.collection('puestos').where('inhabilitado', '==', false).where('area.value', '==', area).get()
          .then((query) => {
            query.forEach((doc) => {
              this.puestos_x_area.push({
                text: doc.data().nombre,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    ver(item) {
      const index = this.convocatorias.indexOf(item)
      router.push(`/admin/convocatoria/${this.convocatorias[index].id}`)
    },
    nueva() {
      router.push('/admin/convocatoria/-1')
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  },
}
</script>