<template>
  <div v-if="item.activa">
    <v-dialog
      v-model="dialog"
      max-width="460px"
      :persistent="load"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
          title="Inhabilitar"
          @click="deleteItem(item)"
        >
          fas fa-minus-circle
        </v-icon>
      </template>
      <v-card>
        <v-card-title>Inhabilitar búsqueda</v-card-title>
        <v-card-text class="body-1">¿Desea cambiar el estado de la búsqueda <strong>{{ title }}</strong> a inactiva?</v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn color="success" class="mr-2" :loading="load" @click="deleteItemConfirm">Sí</v-btn>
          <v-btn color="error" :disabled="load" @click="dialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '../../src/firebase'
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      load: false,
      index: -1,
      title: '',
    }
  },
  props: {
    item: null
  },
  watch: {
    index (val) {
      if (val !== -1) {
        this.title = this.convocatorias[this.index].busqueda
      }
    }
  },
  computed: {
    ...mapState(['convocatorias'])
  },
  methods: {
    ...mapActions(['delete_convocatoria']),

    deleteItem(item) {
      this.index = this.convocatorias.indexOf(item)
      this.dialog = true
    },

    async deleteItemConfirm () {
      this.load = true
      await db.collection('convocatorias').doc(this.convocatorias[this.index].id)
        .update({
          activa: false,
        })
        .then(() => {
          this.delete_convocatoria(this.index)
        })
        .catch((error) => {
          console.error(error)
        })

      this.load = false
      this.dialog = false
    },
  }
}
</script>