<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="900"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="icon">
          <v-icon
            small
            color="primary"
            title="Ver postulantes"
            v-bind="attrs"
            v-on="on"
          >
            fas fa-users
          </v-icon>
        </div>
        <div v-else>
          <v-btn
            color="primary"
            class="ml-4 mb-2 mb-md-0"
            v-bind="attrs"
            v-on="on"
          >
            Ver Postulantes
            <v-icon right>fas fa-users</v-icon>
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="text-h5 font-weight-light">
          <v-row no-gutters>
            <v-col cols="12" md="3" class="d-flex align-center">
              <div v-if="!load" class="mr-2">{{ postulantes.length }}</div>
              Postulante<div v-if="postulantes.length !== 1">s</div>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="search"
                label="Buscar"
                append-icon="fas fa-search"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="postulantes"
            :search="search"
            class="mt-n3"
            :loading="load"
          >
            <template v-slot:no-data>
              <v-alert
                class="mx-auto my-3"
                max-width="400"
                border="left"
                dense
                text
                type="warning"
              >
                No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="d-flex justify-end pb-4">
          <download-excel
            :fetch="get_data"
            :fields="json_fields"
            :name="`postulantes convocatoria ${convocatoria}.xls`"
          >
            <v-btn
              color="green darken-4"
              dark
              :disabled="postulantes.length === 0"
              :loading="downloading"
            >
              Descargar<v-icon right>fas fa-file-download</v-icon>
            </v-btn>
          </download-excel>
          <v-btn
            color="accent"
            class="ml-4"
            @click.stop="dialog = false"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from '../firebase'

export default {
  data () {
    return {
      dialog: false,
      load: false,
      downloading: false,
      postulantes: [],
      search: '',
      headers: [
        { text: 'Apellido', value: 'apellido', sortable: true },
        { text: 'Nombre', value: 'nombre', sortable: true },
        { text: 'Teléfono', value: 'telefono', sortable: false, filterable: false },
        { text: 'Email', value: 'email', sortable: false, filterable: false },
      ],
      json_fields: {
        'Apellido/s': 'apellido',
        'Nombre/s': 'nombre',
        'CUIL': 'cuil',
        'Residencia actual': 'lugar_residencia',
        'Fecha nacimiento': 'fecha_nacimiento',
        'Lugar nacimiento': 'lugar_nacimiento',
        'Teléfono celular': 'telefono_celular',
        'Teléfono alternativo': 'telefono_alternativo',
        'Correo electrónico': 'email',
        'Disponibilidad horaria': 'disp_horaria',
        'Pretensión salarial': 'pretension_salarial',
        'Carnet de conducir': 'carnet_conducir',
      },
    }
  },
  props: {
    convocatoria: String,
    icon: Boolean,
  },
  watch: {
    async dialog (val) {
      if (val) {
        this.get_postulantes()
      }
    }
  },
  methods: {
    async get_postulantes() {
      this.load = true
      this.postulantes = []
      await db.collection('convocatorias').doc(this.convocatoria).collection('postulantes').get()
        .then((query) => {
          query.forEach((doc) => {
            let postulante = doc.data()
            if (postulante.estado) {
              postulante.id = doc.id
              this.postulantes.push(postulante)
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
      this.load = false
    },
    async get_data() {
      this.downloading = true
      let json_data = []
      for (let index = 0; index < this.postulantes.length; index++) {
        const postulante = this.postulantes[index]
        await db.collection('usuarios').doc(postulante.id).get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data().cv
            json_data.push({
              apellido: postulante.apellido,
              nombre: postulante.nombre,
              cuil: data.cuil,
              lugar_residencia: data.lugar_residencia,
              fecha_nacimiento: this.formatDate(data.nacimiento.fecha),
              lugar_nacimiento: data.nacimiento.lugar,
              telefono_celular: postulante.telefono,
              telefono_alternativo: data.telefono.alternativo,
              email: postulante.email,
              disp_horaria: data.disp_horaria,
              pretension_salarial: this.parseMoney(data.pretension_salarial),
              carnet_conducir: this.parseBool(data.carnet_conducir),
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
      }
      this.downloading = false
      return json_data
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseBool (boolean) {
      if (boolean) return 'Sí'
      return 'No'
    },
    parseMoney (money) {
      if (money) return `$${money}`
    },
  }
}
</script>